import React from 'react';
import { canUseWebpAlpha } from './loadimage.js';

export default class AsyncPicture extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = ({
      loaded: false
    });

    this.ref = React.createRef();
  }

  handleSwap = () => {
    this.setState({loaded: true});
  };

  loadImage = async () => {
    const useWebp = await canUseWebpAlpha();
    const image = this.ref.current;
    image.src = (useWebp ? this.props.webp : this.props.fallback);
    image.decode().then(() => this.handleSwap());
  };

  componentDidMount() {
    if (navigator.userAgent === "ReactSnap") {
      return;
    }

    this.loadImage();

    //loader(this.props.webp).then(img => {this.handleSwap(img);});
    /*
    image.onload = () => {
      image.decode().then(() => requestAnimationFrame(this.handleSwap));
    };

    image.onerror = (err) => {
      if (this.props.fallback) {
        image.onerror = (err) => {console.log(err)};
        image.src = this.props.fallback;
      }
      else {
        console.error(err);
      }
    };

    image.src = this.props.webp;

    const canvas = document.createElement("canvas");
canvas.width = e.data.bitmap.width;
    canvas.height = e.data.bitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(e.data.bitmap, 0, 0);
    const img = new Image();
    img.src = canvas.toDataURL();
    */
  }

  componentWillUnmount() {
    this.handleSwap = () => {};
    this.ref = null;
  }

  render() {
    const { alt, height, width, className } = this.props;
    return (
      <div className={className}>
        <div className="async-picture">
          <img className={"img-small" + (this.state.loaded ? "" : " show")}
               src={this.props.thumbnail}
               alt={alt}
               height={height}
               width={width}
          />
          <img ref={this.ref} className={"img-big" + (this.state.loaded ? " show" : "")}
               alt={alt}
               height={height}
               width={width}
          />
        </div>
      </div>
    );
  }
};
