import React from 'react';
import { Helmet } from 'react-helmet';
import { Header, Form, Modal, Button, Message, Grid, Responsive, Divider, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { CONTACT_US, sendContactUsMessage, clearContactUsError } from './api';
import './Contact.css';

const ContactInfo = () => (
  <div>
    <div className="field">
      <div className="label bold">Address:</div>
      <div className="detail">
        Paradise Communications <br />
        4411 Bee Ridge Rd. #292 <br />
        Sarasota, FL 34233
      </div>
    </div>
    <div className="field">
      <div className="label bold">Phone:</div>
      <div className="detail">
        (941) 737-1000
      </div>
    </div>
    <div className="field">
      <div className="label bold">Fax:</div>
      <div className="detail">
        (941) 256-3200
      </div>
    </div>
    <div className="field">
      <div className="label bold">Email:</div>
      <div className="detail">
        <a href="mailto:info@paradisecommunications.com">info@paradisecommunications.com</a>
      </div>
    </div>
  </div>
);

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      to: "info",
      toError: "",
      name: "",
      nameError: false,
      contact: "",
      contactError: false,
      message: "",
      messageError: false,
      errorMessage: "",
      sent: false
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value, [name + "Error"]: false });

  handleSubmit = () => {
    const { to, name, contact, message, sent } = this.state;
    if (sent)
      return;

    if (!name) {
      this.setState({
        nameError: true,
        errorMessage: "Please enter a contact name."
      });
      return;
    }

    if (!contact) {
      this.setState({
        contactError: true,
        errorMessage: "Please enter a contact email or phone number."
      });
      return;
    }

    if (!message) {
      this.setState({
        messageError: true,
        errorMessage: "Message cannot be empty."
      });
      return;
    }

    this.props.sendContactUsMessage(to + "@paradisecommunications.com", "Paradise Website - Send Us a Message", "Name:\n" + name + "\n\nContact Info:\n" + contact + "\n\nMessage:\n" + message);

    this.setState({ sent: true });
  };

  handleCloseError = () => {
    this.setState({ sent: false });
    this.props.clearContactUsError();
  }

  render() {
    const {
      to,
      toError,
      name,
      nameError,
      contact,
      contactError,
      message,
      messageError,
      errorMessage,
      sent
    } = this.state;

    const { apiInProgress, apiError } = this.props;
    return (
      <Form loading={apiInProgress}
            onSubmit={this.handleSubmit}
            error={toError || nameError || contactError || messageError}
            disabled={sent}>
        <Form.Dropdown label="To"
                       name="to"
                       disabled={sent}
                       value={to}
                       onChange={this.handleChange}
                       selection
                       selected={to}
                       options={[
                         {
                           text: "Info",
                           key: "info",
                           value: "info"
                         },
                         {
                           text: "Sales",
                           key: "sales",
                           value: "sales"
                         },
                         {
                           text: "Support",
                           key: "support",
                           value: "support"
                         },
                         {
                           text: "Admin",
                           key: "admin",
                           value: "admin"
                         }]} />
        <Form.Input type="text"
                    label="Your Name"
                    name="name"
                    disabled={sent}
                    value={name}
                    onChange={this.handleChange}
                    error={nameError}
                    placeholder="Enter your name" />
        <Form.Input type="text"
                    label="Your Contact Info"
                    name="contact"
                    disabled={sent}
                    value={contact}
                    onChange={this.handleChange}
                    error={contactError}
                    placeholder="Enter a phone number or email address" />
        <Form.TextArea label="Message"
                       name="message"
                       disabled={sent}
                       value={message}
                       onChange={this.handleChange}
                       error={messageError}
                       rows={10}
                       placeholder="Enter a message" />
        <Form.Button floated="right"
                     color="blue"
                     disabled={sent}
                     content={sent ? "Message Sent" : "Send Message"} />
        <Message error header="Error" content={errorMessage} />
        <Modal id="paradise-contact-error"
               size="mini"
               open={!!apiError}
               onClose={this.handleCloseError}>
          <Modal.Header>
            An Error Has Occurred!
          </Modal.Header>
          <Modal.Content>
            We are unable to send your message at this time. Please try again later.
          </Modal.Content>
          <Modal.Actions>
            <Button content="Close" onClick={this.handleCloseError} />
          </Modal.Actions>
        </Modal>
      </Form>
    );
  }
};

const mapStateToProps = (state) => ({
  apiInProgress: state[CONTACT_US] && state[CONTACT_US].inProgress,
  apiError: state[CONTACT_US] && state[CONTACT_US].error
});

const ConnectedContactForm = connect(mapStateToProps, { sendContactUsMessage, clearContactUsError })(ContactForm);

export default () => (
  <div id="paradise-contact">
    <Helmet>
      <title>Contact Us &middot; Paradise Communications</title>
      <meta name="description" content="Get our contact information or directly send us a message." />
    </Helmet>
    <Segment basic>
    <Grid columns={2} stackable relaxed="very">
      <Grid.Column>
        <Header as="h1">Contact Information</Header>
        <ContactInfo />
      </Grid.Column>
      <Grid.Column>
        <Header as="h1">Send Us a Message</Header>
        <ConnectedContactForm />
      </Grid.Column>
    </Grid>
    <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Divider} vertical>Or</Responsive>
  </Segment>
  </div>
);
