import React from 'react';
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react';
import './Footer.css'

export default () => (
  <div id="paradise-footer">
    <div>
      <Menu compact text>
        <Menu.Item as={NavLink} exact to="/">home</Menu.Item>
        <Menu.Item as={NavLink} exact to="/services.html">services</Menu.Item>
        <Menu.Item as={NavLink} exact to="/about.html">about us</Menu.Item>
        <Menu.Item as={NavLink} exact to="/partners.html">partners</Menu.Item>
        <Menu.Item as={NavLink} exact to="/contact.html">contact us</Menu.Item>
      </Menu>
    </div>
    <p className="copyright">
      &copy; Copyright 2020, Paradise Communications, LLC. All Rights Reserved.
    </p>
  </div>
);
