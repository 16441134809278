import { apiAction, apiError } from '../redux/api';

export const CONTACT_US = 'CONTACT_US';

// Action Functions
export const sendContactUsMessage = (to, subject, message) =>
  apiAction(CONTACT_US, {
      url: "/contact_us/send",
      method: "POST",
      data: {
        toEmails: [to],
        subject,
        message
      }
    });

export const clearContactUsError = () => apiError(CONTACT_US, null);
