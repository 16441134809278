import React from 'react';
import { Helmet } from 'react-helmet';
import { Header, Image } from 'semantic-ui-react';
import AsyncPicture from '../AsyncPicture';
import doggos_webp from './images/doggos82.webp';
import doggos_jpeg from './images/doggos82.jpeg';
import doggos_small from './images/doggos_small.jpeg';

export default () => (
  <div id="paradise-not-found" style={{position: "relative"}}>
    <Helmet>
      <title>404 - Page Not Found &middot; Paradise Communications</title>
      <meta name="description" content="Page not found." />
    </Helmet>
    <Image as={AsyncPicture} className="split-image" floated="left" content=""
           alt="Our Dogs" thumbnail={doggos_small} webp={doggos_webp} fallback={doggos_jpeg} />
    <Header as="h1">
      404 Page Not Found
      <Header.Subheader>
        Sorry, we can't find that page. It might be an old link or maybe it moved.
      </Header.Subheader>
    </Header>
  </div>
);
