import React from 'react';
import { Header, Image, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import AsyncPicture from '../AsyncPicture';
import globerack_webp from './images/globerack4x3.webp';
import globerack_jpeg from './images/globerack4x3s.jpg';
import globerack_small from './images/globerack_small.jpg';


export default () => (
  <div id="paradise-home">
    <Helmet>
        <title>Home &middot; Paradise Communications</title>
        <meta name="description" content="Paradise Communications is a services company located in Bradenton, Florida, serving Sarasota and adjoining cities. We provide a comprehensive suite of technology solutions based on a philosophy of continuous excellence through on time completion, getting it right the first try, and an appreciative accommodation to our customer’s needs." />
    </Helmet>

    <Image as={AsyncPicture} className="split-image" floated="left" content=""
           alt="World Network Rack" thumbnail={globerack_small} webp={globerack_webp} fallback={globerack_jpeg} />
    <Header as="h1">
      Thank you for visiting Paradise Communications!
      <Header.Subheader>
        We have enjoyed the reputation of being South Florida's premier communicatons network infrastructure service for over 20 years.
      </Header.Subheader>
    </Header>
    <p>
      With a legacy of loyal customers built by continuously exceeding their service expectations, our mission statement is quite simple:
    </p>
    <p className="italic">
      “<span className="bold">Paradise Communications</span> is committed to providing a comprehensive suite of technology solutions to its customers based on the simple and straightforward philosophy of continuous excellence in service delivery through on time completion, getting it right the first time, and an appreciative accommodation to our customer’s needs. Each member of our team takes customer satisfaction personally.”
    </p>

    <p>
      Our unique selling proposition is the integration of three basic service solution delivery components which are critical to our success: <span className="bold">Price</span>, <span className="bold">Timeliness</span>, and <span className="bold">Quality of Service</span>. It is the sum of all three that is how we are best measured.
    </p>

    <p>
      Many customer sites require a strong understanding of the different communications infrastructure networks broken down at 3 levels.
    </p>
    <List ordered style={{paddingLeft: "10px"}}>
      <List.Item>
        Enterprise
      </List.Item>
      <List.Item>
        Division
      </List.Item>
      <List.Item>
        Department
      </List.Item>
    </List>
    <p>
      Knowledge of each of these levels helps us to better leverage our service delivery and assist in the design and implementation of new technologies.
    </p>
  </div>
);
