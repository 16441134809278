const createWorker = (f) => {
  return new Worker(URL.createObjectURL(new Blob([`(${f})()`])));
}

const worker = createWorker(() => { /* eslint no-restricted-globals: 1 */
  self.addEventListener('message', e => {
    const src = e.data;
    fetch(src, { mode: 'cors' })
      .then(response => response.blob())
      .then(blob => createImageBitmap(blob))
      .then(bitmap => {
        self.postMessage({ src, bitmap }, [bitmap]);
      })
      .catch(error => {
        self.postMessage({ src, error });
      });
    });
});

const loadImageWithWorker = (src) => {
  if (src.startsWith("/")) {
    src = new URL(src, document.baseURI).href;
  }

  return new Promise((resolve, reject) => {
    function handler(e) {
      if (e.data.src === src) {
        worker.removeEventListener('message', handler);
        if (e.data.error) {
          reject(e.data.error);
        }
        else {
          resolve(e.data.bitmap);
        }
      }
    }
    worker.addEventListener('message', handler);

    worker.postMessage(src);
  });
};

const canUseWebpAlpha = () => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      var result = (img.width > 0 && img.height > 0);
      resolve(result);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==";
  });
};

const loadImageWithImageTag = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = '';
    img.src = src;
    img.onload = () => { resolve(img); };
    img.onerror = () => { reject(img); };
  });
}

const loader = (!!window.createImageBitmap && !!window.Worker) ? loadImageWithWorker : loadImageWithImageTag;

export { loader, loadImageWithImageTag, loadImageWithWorker, canUseWebpAlpha };

// const loader = loadImageWithImageTag;
/*
const loader = loadImageWithWorker;

loader('https://i.imgur.com/NTBhJwl.jpg').then(img => {
    const ctx = document.querySelector('canvas').getContext('2d');
    ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
});
*/
