import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from 'semantic-ui-react';
import Routes from './Routes';
import Header from './header';
import Footer from './footer';
import './App.css';

const ogUrl = process.env.REACT_APP_WWW_URL || "https://www.paradisecommunications.com";

export default () => (
  <Router>
    <div className="App">
      <Helmet>
          <title>Paradise Communications</title>
          <meta property="og:title" content="Paradise Communications" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={ogUrl} />
          <meta property="og:image" content={`${ogUrl}/logo.jpg`} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="1200" />
          <meta property="og:site_name" content="Paradise Communications" />
          <meta property="og:description" content="Paradise Communications is a telecommunication services company located in Bradenton, Florida, serving Sarasota and adjoining cities." />
      </Helmet>
      <div className="wrapper-top">
        <Container text>
          <Header />
          <Routes />
        </Container>
      </div>
      <div className="wrapper-bottom">
        <Container text>
          <Footer />
        </Container>
      </div>
    </div>
  </Router>
);
