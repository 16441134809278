import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import './semantic-ui/semantic.less';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const ProviderApp = () => (
  <Provider store={configureStore()}>
    <App />
  </Provider>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<ProviderApp />, rootElement);
}
else {
  ReactDOM.render(<ProviderApp />, rootElement );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
