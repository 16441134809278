import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Home from './home';
import About from './about';
import Services from './services';
import Partners from './partners';
import Contact from './contact';
import NotFound from './notfound';

const ScrollToTop = withRouter(({history}) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0,0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return null;
});

export default () => (
  <React.Fragment>
    <ScrollToTop />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about.html" exact component={About} />
      <Route path="/services.html" exact component={Services} />
      <Route path="/partners.html" exact component={Partners} />
      <Route path="/contact.html" exact component={Contact} />
      <Route path="/404.html" exact component={NotFound} status={404} />
      <Redirect to="/404.html" />
    </Switch>
  </React.Fragment>
);
