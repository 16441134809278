import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from 'semantic-ui-react';

export default () => (
  <div id="paradise-about">
    <Helmet>
      <title>About &middot; Paradise Communications</title>
      <meta name="description" content="Paradise Communications was founded in 2000 in Sarasota, Florida." />
    </Helmet>
    <Header as="h1">A Few Words About Paradise Communications</Header>
    <p>
      <span className="bold">Paradise Communications</span> was founded in 2000 in Sarasota, Florida. Our business model was simple and straightforward in that our initial offering was in two commercial voice and data communication infrastructure services that largely utilized proprietary analog based networking solutions that did not integrate with each other easily.
    </p>
    <p>
      Over the years technological advancements with surveillance, access control, paging, and audio visual systems evolved to take advantage of digital technologies over IP environments. The result was a significant convergence and integration of networking infrastructures with increased bandwidth over open system protocols. These environments now run over high speed copper and fiber backbone cabling connected to open system based servers.
    </p>
    <p>
      The past 10 years has not been evolutionary but more revolutionary in the acceptance and use of these converging networks. Yet, one basic requirement has increased in importance and that is service delivery. Planning, installing, and managing a common network system infrastructure impacts several integrated enterprise applications.
    </p>
    <p>
      This is where <span className="bold">Paradise Communications</span> has a superior value proposition. We understand that service delivery impacts cost of installation, timely delivery, and consistent, uninterrupted service levels. If you think about the largest single expense over the 5 to 10 year life-cycle of any technology, you realize that it is service levels and associated service costs.
    </p>
    <p>
      We are known for highly skilled, knowledgeable, an accommodating service techs in Southwest Florida. We invite you to talk to any of our customers and they will all tell you the same thing. It is our service and customer focused approach that promotes our strong customer base.
    </p>
  </div>
)
