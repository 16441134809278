import React from 'react';
import { Helmet } from 'react-helmet';
import { Header, List } from 'semantic-ui-react';
import './Services.css';

export default () => (
  <div id="paradise-services">
    <Helmet>
      <title>Services &middot; Paradise Communications</title>
      <meta name="description" content="Paradise Communications provides technology solutions, structured cabling, access control, audio/visual, surveillance camera security, and paging services to the commercial marketplace. Additionally we offer design, project management, engineering, implementation, and certification of our services for our clients." />
    </Helmet>
    <Header as="h1">Solution Offerings and Services</Header>
    <p>
      <span className="bold">Paradise Communications</span> provides technology solutions, structured cabling, access control, audio/visual, surveillance camera security, and paging services to the commercial marketplace. Additionally we offer design, project management, engineering, implementation, and certification of our services for our clients.
    </p>
    <Header as="h2">Structured Cable</Header>
    <p>
      <span className="bold">Paradise Communications</span> cabling services utilize fiber-optic, copper and coaxial solutions to provide building and outdoor plant cabling. Our staff has completed work on both large and small projects including experiences such as the Sarasota Memorial Hospital, Goodwill Industries sites in Southwest Florida, and DFAS-Indianapolis Finance Center, which, at 1.54 million square feet, was the 2nd largest Department of Defense office building in the world. We offer additional solutions including Canobeam Free Space Optic Data solutions providing over-the-air gigabit laser connectivity. We can provide general construction in support of our projects.
    </p>
    <Header as="h2">Access Control &amp; Security</Header>
    <p>
      <span className="bold">Paradise Communications</span> offers a comprehensive suite of access control and security solutions for a variety of AC reader systems along with digital, fixed, and PTZ cameras, digital video recorders, and monitors that support both web and telephone alarm notifications.
    </p>
    <Header as="h2">Data Centers</Header>
    <p>
      <span className="bold">Paradise Communications</span> delivers the following data center solutions:
    </p>
    <List >
      <List.Item icon="info" header="Assessments"
                 description="Evaluation of HVAC, electrical, environmental, and infrastructure issues that provide clear insight into strengths, vulnerabilities, and appropriate remedies to meet IAW Data Center Standards and Codes." />
      <List.Item icon="wrench" header="Build Out"
                 description="Upgrade and expansion of data center and environmental systems." />
      <List.Item icon="wifi" header="Infrastructure"
                 description="Connectivity solutions that provide up to 40 gigabit capabilities." />
    </List>
    <Header as="h2">Audio Visual Services</Header>
    <p>
      <span className="bold">Paradise Communications</span> offers a full suite of AV commercial solutions. Our business presentation components include sound, video, lighting, display, and projection systems. Our applications are used in presentation rooms, auditoriums, command and control centers as well as digital signage.
    </p>
    <Header as="h2">Engineering &amp; Certifications</Header>
    <p>
      <span className="bold">Paradise Communications</span> provides engineering, certifications, and data center requirements for our infrastructure, access control, security, fiber, and broadband coax solutions.
    </p>
  </div>
);
