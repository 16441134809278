import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Responsive, Loader, Accordion, Menu, Icon, Button, Label } from 'semantic-ui-react';
import { loader, canUseWebpAlpha } from '../loadimage.js';

import './Header.css';

import backdrop_webp from './images/backdrop.webp';
import backdrop_jpeg from './images/backdrop.jpg';
import clouds_webp from './images/clouds.webp';
import clouds_png from './images/clouds.png';
import flare_webp from './images/flare.webp';
import flare_png from './images/flare.png';
import world_webp from './images/world.webp';
import world_png from './images/world.png';
import paradise_png from './images/paradise.png';
import globe_webp from './images/globe.webp';
import globe_png from './images/globe.png';
import modem_webp from './images/modem.webp';
import modem_png from './images/modem.png';
import dish_webp from './images/dish.webp';
import dish_png from './images/dish.png';

const PureCanvasBanner = ({canvasRef, children}) => (
  <canvas className="banner-canvas"
          width={943}
          height={338}
          ref={(node) => node ? canvasRef(node) : null}>
    {children}
  </canvas>
);

class AnimatedCanvasBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: true
    };
    this.lastUpdate = 0;
    this.step = 0;
    this.world_frame = 0;
    this.clouds_frame = 0;
    this.flare_frame = -100;
    this.paradise_frame = -120;
  }

  saveCanvas = (canvas) => {
    if (this.canvas !== canvas) {
      this.canvas = canvas;
      if ("OffscreenCanvas" in window)
        this.ctx = this.canvas.transferControlToOffscreen().getContext('2d');
      else
        this.ctx = this.canvas.getContext('2d');
      this.width = canvas.width;
      this.height = canvas.height;
    }
  }

  draw = () => {
    const clouds_clip_x = 1682 - 720 - (this.clouds_frame / 5.0);
    const world_clip_x = 492 * (Math.floor(this.world_frame) % 16);
    const world_clip_y = 360 * Math.floor(this.world_frame / 16);
    const flare_width = this.flare_frame < 0 ? 0 : (this.flare_frame / 20) * 801;
    const flare_clip_x = 801 - flare_width;
    const paradise_scale = this.paradise_frame < 0 ? 1.0 :
                           this.paradise_frame < 5 ? 1.0 + 0.25 * (this.paradise_frame / 5) :
                           1.25 - 0.25 * ((this.paradise_frame - 5) / 5);

    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.drawImage(this.backdrop_img, 0, 10, 933, 363, 0, 0, this.width, 280);

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.ellipse(this.width, this.height/4, 720, 400, 0, 0, 2 * Math.PI);
    this.ctx.clip();
    this.ctx.drawImage(this.clouds_img,
                       clouds_clip_x, 0, 720, 284,
                       223, 0, 720, 284);

    if (flare_width > 0)
      this.ctx.drawImage(this.flare_img,
                         flare_clip_x, 0, flare_width, 284,
                         943 - flare_width, -20, flare_width, 284);

    this.ctx.restore();
    this.ctx.save();
    const gradient = this.ctx.createLinearGradient(0, 260, 0, 338);
    gradient.addColorStop(0, '#ffffff');
    gradient.addColorStop(.8, '#ddd');
    gradient.addColorStop(1, '#ccc');
    this.ctx.fillStyle = gradient;

    this.ctx.fillRect(0, 250, 310, 83);
    this.ctx.fillRect(317, 250, 310, 83);
    this.ctx.fillRect(634, 250, 310, 83);

    this.ctx.drawImage(this.globe_img, 10, 255, 75, 81);
    this.ctx.drawImage(this.dish_img, 327, 252, 49, 86);
    this.ctx.drawImage(this.modem_img, 644, 252, 60, 84);

    this.ctx.shadowColor = "#666";
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 1;
    this.ctx.shadowOffsetY = 1;

    this.ctx.strokeStyle = "black";
    this.ctx.fillStyle = "#ff6633";
    this.ctx.font = "italic bold 22px Arial";
    this.ctx.fillText("Complete", 100, 295);
    this.ctx.fillText("Committed To", 390, 295);
    this.ctx.fillText("Passion For", 709, 295);

    this.ctx.shadowColor = "#000033";
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;

    this.ctx.fillStyle = "#2266cc";
    this.ctx.font = "italic bold 17px arial";
    this.ctx.fillText("Solution Suite", 140, 317);
    this.ctx.fillText("Service Delivery", 430, 317);
    this.ctx.fillText("Customer Satisfaction", 724, 317);
    this.ctx.restore();

    this.ctx.drawImage(this.world_img,
                       world_clip_x, world_clip_y, 492, 360,
                       466, -15, 492, 360);

    this.ctx.save();
    this.ctx.shadowColor = "#222277";
    this.ctx.shadowBlur = 5;
    this.ctx.shadowOffsetX = 2;
    this.ctx.shadowOffsetY = 2;
    this.ctx.drawImage(this.paradise_img,
                       0, 0, 401, 132,
                       50 - 401 * (paradise_scale-1) / 2, 40 - 132 * (paradise_scale-1) / 2, 401 * paradise_scale, 132 * paradise_scale);
    this.ctx.restore();
  }

  updateAnimationState = (time) => {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
    this.step = this.step + ((this.lastUpdate > 0) ? (time - this.lastUpdate) * 30.0 / 1000.0 : 0);
    this.lastUpdate = time;
    if (this.step < 1.0)
      return;

    const frames = Math.floor(this.step);
    this.step = this.step - frames;

    this.world_frame += frames;
    if (this.world_frame >= 201) {
      this.world_frame = 102;
    }

    this.clouds_frame = (this.clouds_frame + frames) % 4000;
    this.flare_frame = Math.min(this.flare_frame + frames, 20);
    this.paradise_frame = Math.min(this.paradise_frame + frames, 10);

    this.draw();
  }

  startAnimation = async () => {
    const useWebp = await canUseWebpAlpha();
    const backdrop_src = new URL((useWebp ? backdrop_webp : backdrop_jpeg), document.baseURI).href;
    const clouds_src = new URL((useWebp ? clouds_webp : clouds_png), document.baseURI).href;
    const flare_src = new URL((useWebp ? flare_webp : flare_png), document.baseURI).href;
    const world_src = new URL((useWebp ? world_webp : world_png), document.baseURI).href;
    const paradise_src = new URL(paradise_png, document.baseURI).href;
    const globe_src = new URL((useWebp ? globe_webp : globe_png), document.baseURI).href;
    const modem_src = new URL((useWebp ? modem_webp : modem_png), document.baseURI).href;
    const dish_src = new URL((useWebp ? dish_webp : dish_png), document.baseURI).href;

    var promises = [];

    if (!this.backdrop_img) {
      promises.push(loader(backdrop_src).then(img => {this.backdrop_img = img;}));
    }

    if (!this.clouds_img) {
      promises.push(loader(clouds_src).then(img => {this.clouds_img = img;}));
    }

    if (!this.flare_img) {
      promises.push(loader(flare_src).then(img => {this.flare_img = img;}));
    }

    if (!this.world_img) {
      promises.push(loader(world_src).then(img => {this.world_img = img;}));
    }

    if (!this.paradise_img) {
      promises.push(loader(paradise_src).then(img => {this.paradise_img = img;}));
    }

    if (!this.globe_img) {
      promises.push(loader(globe_src).then(img => {this.globe_img = img;}));
    }

    if (!this.modem_img) {
      promises.push(loader(modem_src).then(img => {this.modem_img = img;}));
    }

    if (!this.dish_img) {
      promises.push(loader(dish_src).then(img => {this.dish_img = img;}));
    }

    return Promise.all(promises).then(() => {
      if (this.props.active && this.backdrop_img && this.clouds_img && this.flare_img &&
          this.world_img && this.paradise_img && this.globe_img && this.modem_img && this.dish_img) {
        this.setState({active: true, loading: false},
          () => {
            this.rAF = requestAnimationFrame(this.updateAnimationState);
          }
        );
      }
    });
  }

  stopAnimation = () => {
    this.setState({active: false}, () => {
      if (this.rAF) {
        cancelAnimationFrame(this.rAF);
        this.rAF = null;
      }
      this.lastUpdate = 0;
      this.step = 0;
    });
  }

  handleStartStop = () => {
    if (this.state.active)
      this.stopAnimation();
    else
      this.startAnimation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.active && this.props.active) {
      this.startAnimation();
    }
    else if (prevProps.active && !this.props.active) {
      this.stopAnimation();
    }
  }

  componentDidMount() {
    if (navigator.userAgent === "ReactSnap")
      return;

    if (this.props.active)
      this.startAnimation();
  }

  componentWillUnmount() {
    this.stopAnimation();

    if (this.backdrop_img) {
      delete this.backdrop_img;
    }

    if (this.clouds_img) {
      delete this.clouds_img;
    }

    if (this.flare_img) {
      delete this.flare_img;
    }

    if (this.world_img) {
      delete this.world_img;
    }

    if (this.paradise_img) {
      delete this.paradise_img;
    }

    if (this.globe_img) {
      delete this.globe_img;
    }

    if (this.modem_img) {
      delete this.modem_img;
    }

    if (this.dish_img) {
      delete this.dish_img;
    }
  }

  render() {
    return (
      <div className="canvas-container">
        <Loader active={this.state.loading} content="Loading" />
        <PureCanvasBanner canvasRef={this.saveCanvas}>
          Paradise Communications - Sorry, your browser does not support HTML5 canvas images.
        </PureCanvasBanner>
        <Button className={"play-pause-button" + (!this.state.loading ? "" : " hidden")}
              size="mini"
              aria-label={this.state.active ? "pause" : "play"}
              color="grey"
              basic
              icon={this.state.active ? "pause" : "play"}
              onClick={this.handleStartStop} />
      </div>
    );
  }
}

const MobileHeader = () => {
  const [active, setActive] = useState(false);
  const toggleActive = () => setActive(!active);
  return (
    <div className="mobile">
      <Accordion fluid>
        <Accordion.Title as={Button} fluid color="blue" size="huge"
          active={active}
          onClick={toggleActive}><Icon name="bars" />Paradise Communications</Accordion.Title>
        <Accordion.Content as={Menu} vertical active={active} fluid inverted color="blue">
          <Menu.Item as={NavLink} exact to='/' onClick={toggleActive}>Home</Menu.Item>
          <Menu.Item as={NavLink} exact to="/services.html" onClick={toggleActive}>Services</Menu.Item>
          <Menu.Item as={NavLink} exact to="/about.html" onClick={toggleActive}>About Us</Menu.Item>
          <Menu.Item as={NavLink} exact to="/partners.html" onClick={toggleActive}>Partners</Menu.Item>
          <Menu.Item as={NavLink} exact to="/contact.html" onClick={toggleActive}>Contact Us</Menu.Item>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

const ComputerHeader = () => {
  const [state, setState] = React.useState({
    width: getWidth()
  });
  React.useEffect(() => {
      const handleResize = () => setState({width: getWidth()});
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  );

  return (
    <div className="computer">
      <div className="label-container">
        <Label className="phone-label" basic size="large" horizontal>
          <Icon name="call" />
          <span className="label-name">phone:</span>
          <Label.Detail>(941) 737-1000</Label.Detail>
        </Label>
      </div>
      <Menu className="top-nav-bar" size="large" >
        <Menu.Item as={NavLink} exact to='/'>Home</Menu.Item>
        <Menu.Item as={NavLink} exact to="/services.html">Services</Menu.Item>
        <Menu.Item as={NavLink} exact to="/about.html">About Us</Menu.Item>
        <Menu.Item as={NavLink} exact to="/partners.html">Partners</Menu.Item>
        <Menu.Item as={NavLink} exact to="/contact.html">Contact Us</Menu.Item>
      </Menu>
      <AnimatedCanvasBanner active={state.width >= Responsive.onlyTablet.minWidth} />
    </div>
  );
};

const ResponsiveHeader = ({getWidth}) => (
  <React.Fragment>
    <MobileHeader />
    <ComputerHeader />
  </React.Fragment>
)

const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyMobile.maxWidth : window.innerWidth;
};

export default () => (
  <div id="paradise-header">
    <ResponsiveHeader/>
  </div>
);
