import React from 'react';
import { Header, Grid, Image } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import './Partners.css';
import canon_logo from './images/canon_logo.svg';
import samsung_logo from './images/samsung_logo.svg';
import bicsi_logo from './images/bicsi_logo.svg';
import tia_logo from './images/tia_logo.svg';
import belden_logo from './images/belden_logo.svg';
import corning_logo from './images/corning_logo.svg';
import leviton_logo from './images/leviton_logo.svg';
import berktek_logo from './images/berktek_logo.svg';
import openeye_logo from './images/openeye_logo.svg';
import ortronics_logo from './images/ortronics_logo.svg';

export default () => (
  <div id="paradise-partners">
    <Helmet>
      <title>Partners &middot; Paradise Communications</title>
      <meta name="description" content="See some of our corporate partners whose expertise enables us to do far more than we could alone." />
    </Helmet>
    <Header as="h1">Partners</Header>
    <p>
      To achieve the goals of each project, <span className="bold">Paradise Communications</span> partners with many extraordinary organizations. Their expertise enables us to do far more than we could alone. It is our pleasure to introduce you to a handful of the organizations whose accomplishments and commitments are representative of our business climate and we are fortunate to call them our partners.
    </p>
    <Grid container className="partners-grid" verticalAlign="middle" relaxed doubling columns={5}>
      <Grid.Column><Image src={canon_logo} alt="Canon" /></Grid.Column>
      <Grid.Column><Image src={samsung_logo} alt="Samsung" /></Grid.Column>
      <Grid.Column><Image src={bicsi_logo} alt="Bicsi" style={{marginTop: "-10px"}} /></Grid.Column>
      <Grid.Column><Image src={tia_logo} alt="TIA" /></Grid.Column>
      <Grid.Column><Image src={belden_logo} alt="Belden" /></Grid.Column>
      <Grid.Column><Image src={corning_logo} alt="Corning" /></Grid.Column>
      <Grid.Column><Image src={leviton_logo} alt="Leviton" /></Grid.Column>
      <Grid.Column><Image src={berktek_logo} alt="Berk-Tek" /></Grid.Column>
      <Grid.Column><Image src={openeye_logo} alt="OpenEye" /></Grid.Column>
      <Grid.Column><Image src={ortronics_logo} alt="Ortronics" /></Grid.Column>
    </Grid>
  </div>
);
