import axios from 'axios';

export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';

export const apiAction = (label, { url = "",
                                   method = "GET",
                                   data = null,
                                   accessToken = null,
                                   onSuccess = () => {},
                                   onFailure = () => {},
                                   headersOverride = null
                                 }) => ({
  type: API,
  payload: {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onFailure,
    label,
    headersOverride
  }
});

export const apiStart = (label) => ({
  type: API_START,
  label
});

export const apiEnd = (label) => ({
  type: API_END,
  label
});

export const apiError = (label, error) => ({
  type: API_ERROR,
  label,
  error
});

export const apiReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case API_START:
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          inProgress: true
        }
      };
    case API_END:
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          inProgress: false
        }
      };
    case API_ERROR:
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          error: action.error
        }
      };
    default:
      return state;
  }
};

export const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type !== API)
    return;

  const { url, method, data, accessToken, onSuccess, onFailure, label, headers } = action.payload;
  const dataParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  if (accessToken)
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  dispatch(apiStart(label));
  axios.request({ url, method, headers, [dataParams]: data })
    .then(({ data }) => onSuccess(data),
          (error) => {
            dispatch(apiError(label, error));
            onFailure(error);
          })
    .finally(() => { dispatch(apiEnd(label)); });
};
